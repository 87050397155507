'use client'
import style from './BasicButton.module.css'

interface BasicButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  as?: 'link' | 'button'
  to?: string
  target?: string
  size: string
  onClick?: () => void
  children: React.ReactNode
  background: string
  loading?: boolean
  className?: string
}

const BasicButton = ({
  as = 'button',
  to,
  target,
  size,
  onClick,
  children,
  background,
  loading,
  className = '',
  ...props
}: BasicButtonProps) => {
  const classNames = `${className} ${style.btn} ${style[size]} ${style[background]} ${loading ? style.loading : ''}`

  if (as === 'link' && to) {
    return (
      <a href={to} className={classNames} target={target}>
        {loading ? (
          <div className={style.spinnerContainer}>
            <div className={style.spinner}>
              <div></div>
            </div>
          </div>
        ) : (
          children
        )}
      </a>
    )
  }

  return (
    <button className={classNames} onClick={onClick}>
      {loading ? (
        <div className={style.spinnerContainer}>
          <div className={style.spinner}>
            <div></div>
          </div>
        </div>
      ) : (
        children
      )}
    </button>
  )
}

export default BasicButton
